.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 80px;  /* Adjust the height as necessary */
  width: auto;
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 5px;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 60%;  /* Adjust the width as necessary */
  padding: 15px;
}

.nav-links li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 10px 15px;
}

.nav-links li a:hover {
  color: #007BFF; /* Optional hover color */
}

body {
  padding-top: 60px;  /* Ensure content doesn't hide behind the fixed navbar */
}

.hamburger {
  display: none;
}

/* Mobile-Only Adjustments */
@media (max-width: 768px) {
  body {
    padding-top: 60px; /* Adjust content padding for mobile */
  }

  .logo {
    height: 60px;
  }

  /* Navbar layout for mobile */
  .navbar {
    justify-content: space-between;
    padding: 10px;
    height: 10.5%;
  }

  /* Hide the nav links by default (for hamburger menu) */
  .nav-links {
    position: absolute;
    top: 70%;
    right: 0;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    visibility: hidden;
  }

  .nav-links li {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .nav-links.active {
    height: 250px; /* Show the menu with height change */
    width: 100%;
    visibility: visible;
  }

  /* Hamburger menu */
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
  }

  /* Ensure the logo stays visible and centered */
  .logo-container {
    margin-left: 10px;
  }
}
