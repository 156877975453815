@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400&display=swap');
/* App.css */

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  scroll-behavior: auto;
}

.App {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

* {
  box-sizing: border-box;  /* Ensure all elements respect padding/borders in width */
}

h1 {
  margin: 0;
}

/* Each section takes full page height and scroll snaps */
.section {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  scroll-snap-align: start;
}

.mySwiper {
  width: 100%;
  height: 100%;
  z-index: 15;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small {
  margin-right: .5%;
}

/* Container for the big text */
.big-text-container {
  position: absolute; /* Position above the Swiper */
  z-index: 16; /* Ensure the text is on top of the Swiper */
  top: 40%;
  width: 80%;
}

/* Big centered text styling */
.big-text {
  font-size: 4rem; /* Adjust size to fit most screens */
  font-weight: bold;
  text-align: center;
  color: rgba(128, 128, 128, .8); /* Inside grey with 70% opacity */
  -webkit-text-stroke: 2px black; /* Outside black border */
  line-height: 1; /* Tighter line-height to keep the text compact */

}

/* Logo styling */
.hide {
  position: fixed;
  opacity: 0;
  transition: opacity 1s ease; /* Smooth transition for visibility */
}

.hide.visible {
  opacity: 1; /* Show the logo when the 'visible' class is added */
  z-index: 16; /* Ensure it's on top when visible */
}

.hide.hidden {
  opacity: 0; /* Hide the logo when the 'hidden' class is added */
  z-index: -1; /* Move it behind other elements */
}

.special-text {
  position: relative; /* Ensure the pseudo-element is positioned relative to the text */
  white-space: nowrap; /* Prevent the text from wrapping to a new line */
}

/* Glow Effect on Hover */
.special-text:hover {
  color: #fff; /* Reverts to solid color on hover */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

/* Animated Underline */
.special-text::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px; /* Positioned below the text */
  width: 100%;
  height: 2px;
  background-color: #ffffff; /* Underline color */
  transform: scaleX(0); /* Hidden initially */
  transition: transform 2s ease;
}

.special-text:hover::after {
  transform: scaleX(1); /* Expands underline on hover */
}

.arrow-down-container {
  position: absolute;
  bottom: 20px; /* Adjusts how far from the bottom */
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 16;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  border-top: 48px solid rgba(128, 128, 128, .8); /* Adjust size and color of the arrow */
  animation: bounce 1.5s infinite;
}

.arrow-down:hover {
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  border-top: 48px solid rgba(255, 255, 255, 0.8);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(5px);
  }
}

.section {
  min-height: 100vh;  /* Make sure each section takes full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #aaa18eb7;  /* Optional: Set a background color */
}

.section p {
  font-size: 3rem;  /* Adjust font size as needed */
}

/* Optional CSS for smoother transitions on rotating words */
span {
  transition: opacity 0.5s ease-in-out;
}

#what-we-are {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;  /* Ensure the section takes the full viewport height */
}

#what-we-are .content-container {
  display: flex;
  width: 100%;
  /* padding-top: 7%; */
  max-width: 1200px;  /* Limit the max width of the content */
  justify-content: space-between;  /* Spread the text and image content apart */
  align-items: center;
}

#what-we-are .text-content {
  flex: 1;  /* Text content takes up 50% of the width */
  padding-right: 20px;  /* Add some space between text and image */
  text-align: left;  /* Align text to the left */
}

#what-we-are .text-content p {
  font-size: 1.5rem;  /* Make the font smaller */
  line-height: 1.4;  /* Line spacing for better readability */
  color: #333;  /* Text color */
}

#what-we-are .image-content {
  flex: 1;  /* Image content takes up the other 50% */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

#what-we-are .mission-image {
  max-width: 90%;
  height: 100%;  /* Ensure the image scales properly */
  border-radius: 8px;  /* Optional: Rounded corners */
  object-fit: cover;
}

#what-we-plan-to-do {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;  /* Ensure the section takes the full viewport height */
}

#what-we-plan-to-do .content-container {
  display: flex;
  width: 100%;
  padding-top: 4%;
  max-width: 1200px;  /* Limit the max width of the content */
  justify-content: space-between;  /* Spread the text and image content apart */
  align-items: center;
}

#what-we-plan-to-do .text-content {
  flex: 1;  /* Text content takes up 50% of the width */
  text-align: left;  /* Align text to the left */
  padding-left: 20px;
}

#what-we-plan-to-do .text-content p {
  font-size: 1.4rem;  /* Make the font smaller */
  line-height: 1.4;  /* Line spacing for better readability */
  color: #333;  /* Text color */
}

#what-we-plan-to-do .image-content {
  flex: 1;  /* Image content takes up the other 50% */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

#what-we-plan-to-do .mission-image {
  max-width: 90%;
  height: 100%;  /* Ensure the image scales properly */
  border-radius: 8px;  /* Optional: Rounded corners */
  object-fit: cover;
}

#reach-out-to-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

#reach-out-to-us h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

#reach-out-to-us p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  max-width: 600px;
}

#reach-out-to-us .contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
}

#reach-out-to-us .contact-item {
  flex: 1 1 300px;  /* Each item takes up a third of the row, adjust as needed */
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

#reach-out-to-us .contact-item a {
  text-decoration: none;
  color: #0073b1;
  font-size: 1.1rem;
  display: inline-flex; /* Change from flex to inline-flex */
  align-items: center;
}

#reach-out-to-us .contact-item a:hover {
  color: #005582;
}

#reach-out-to-us .contact-item i {
  margin-right: 10px;
  font-size: 1.5rem;
}

/* Optional: Responsiveness */
@media (max-width: 768px) {
  .App {
    scroll-snap-type: none; /* Disable snap scrolling on mobile */
  }

  .section {
    max-height: 60vh;
    height: 10%;
    min-height: 20vh;
  }

  .first-section {
    min-height: 100vh;
  }

  #reach-out-to-us .contact-item {
    flex: 1 1 100%;  /* Stack items on smaller screens */
  }

  /* Make the text smaller for mobile */
  .big-text {
    font-family: 'Georgia', serif; /* Use a traditional serif font */
    font-size: 2.5rem;  /* Adjust text size for mobile */
    color: rgba(128, 128, 128, 1); /* Inside grey with 70% opacity */
    -webkit-text-stroke: 1px black; /* Outside black border */

  }

  /* Swiper height for mobile */
  .mySwiper {
    height: 100vh;  /* Reduce the height for mobile */
  }

  .section p {
    font-size: 1.5rem;  /* Smaller text on mobile */
  }

  .special-text {
    overflow: auto;
    white-space: normal; /* Allow text to wrap if needed */
    text-align: center;  /* Center the text */
    font-size: 3.5rem;   /* Smaller font size on mobile */
  }

  /* Adjust section layout for mobile */
  #what-we-are .content-container, #what-we-plan-to-do .content-container {
    flex-direction: column;  /* Stack content vertically on mobile */
    margin-left: 20px;
    margin-right: 20px;
  }

  #what-we-do {
    min-height: 56vh;
  }

  #what-we-are .text-content, #what-we-plan-to-do .text-content {
    padding: 10px 0;  /* Adjust padding for mobile */
    text-align: center;  /* Center text on mobile */
  }

  #what-we-are .text-content p{
    font-size: 1rem;
  }

  #what-we-plan-to-do {
    padding-top: 300px;
    flex-direction: row-reverse;
    padding-bottom: 500px;
  }

  #what-we-plan-to-do .content-container p {
    font-size: .90rem;
  }

  
  #what-we-plan-to-do .content-container h2 {
    margin: 0;
  }

  #what-we-plan-to-do .text-content {
    padding-bottom: 20px;
  }

  
  #what-we-plan-to-do .content-container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  #what-we-plan-to-do .image-content {
    visibility: hidden;
  }

  #what-we-are .image-content, #what-we-plan-to-do .image-content {
    height: auto;  /* Allow images to resize on mobile */
    width: 60%;
  }

  .mission-image {
    max-width: 80%;  /* Ensure images don't overflow */
    height: auto;
  }

  /* Contact section adjustments for mobile */
  #reach-out-to-us .contact-container {
    flex-direction: row;  /* Stack contacts vertically on mobile */
    gap: 10px;
  }

  #reach-out-to-us .contact-item {
    width: 100%;
    padding: 10px;
  }
}
